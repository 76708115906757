.tree {
  font-size: 14px;
  padding: 2px 0;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  color: #444;

  &-row {
    &-container {
      cursor: pointer;
    }

    .text {
      padding-left: 5px;
    }

    $step: 20px;

    &.level-0 {
      padding-left: 0;
    }

    &.level-1 {
      padding-left: $step;
    }

    &.level-2 {
      padding-left: $step * 2;
    }

    &.level-3 {
      padding-left: $step * 3;
    }

    &.level-4 {
      padding-left: $step * 4;
    }
  }

  &-folder {
    color: #2185d0;

    &_open {
      color: #2185d0;
    }

  }

  &-file {
    margin-left: 21px;
    color: #222;
  }

  &-chevron {
    transition: transform 0.1s linear !important;

    &_rotated {
      transform: rotate(90deg);
    }

  }

  &-children {
    overflow: hidden;
    transition: height 0.2s ease-in;

    &_collapsed {
      height: 0;
    }

  }

  &-checkbox {
    $color: #2b78d7;
    $size: 16px;
    $fontSize: 14px;

    width: $size;
    height: $size;
    display: inline-block;
    background-color: lighten($color, 40%);
    color: white;
    text-align: center;
    font-weight: bold;
    font-size: $fontSize;
    position: relative;
    border: 1px solid white;
    outline: 1px solid #ddd;
    line-height: $fontSize;
    margin-right: 5px;

    &::after {
      content: "\a0";
    }

    &_isIndeterminate {
      background-color: orange;

      &::after {
        content: "•";
      }
    }

    &_isChecked {
      background-color: $color;

      &::after {
        content: "✓";
      }
    }
  }

  &-list {
    padding: 0;
    margin: 0;
    list-style-type: none;

    &-item {
      padding: 3px;
      color: #444;
      font-size: 12px;
      list-style-type: none;

      $step: 10px;

      &.level-0 {
        padding-left: 0;
      }

      &.level-1 {
        padding-left: $step;
      }

      &.level-2 {
        padding-left: $step * 2;
      }

      &.level-3 {
        padding-left: $step * 3;
      }

      &.level-4 {
        padding-left: $step * 4;
      }

    }
  }

}