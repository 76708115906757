@charset "UTF-8";
.tree {
  font-size: 14px;
  padding: 2px 0;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  color: #444;
}
.tree-row-container {
  cursor: pointer;
}
.tree-row .text {
  padding-left: 5px;
}
.tree-row.level-0 {
  padding-left: 0;
}
.tree-row.level-1 {
  padding-left: 20px;
}
.tree-row.level-2 {
  padding-left: 40px;
}
.tree-row.level-3 {
  padding-left: 60px;
}
.tree-row.level-4 {
  padding-left: 80px;
}
.tree-folder {
  color: #2185d0;
}
.tree-folder_open {
  color: #2185d0;
}
.tree-file {
  margin-left: 21px;
  color: #222;
}
.tree-chevron {
  transition: transform 0.1s linear !important;
}
.tree-chevron_rotated {
  transform: rotate(90deg);
}
.tree-children {
  overflow: hidden;
  transition: height 0.2s ease-in;
}
.tree-children_collapsed {
  height: 0;
}
.tree-checkbox {
  width: 16px;
  height: 16px;
  display: inline-block;
  background-color: #d7e5f7;
  color: white;
  text-align: center;
  font-weight: bold;
  font-size: 14px;
  position: relative;
  border: 1px solid white;
  outline: 1px solid #ddd;
  line-height: 14px;
  margin-right: 5px;
}
.tree-checkbox::after {
  content: " ";
}
.tree-checkbox_isIndeterminate {
  background-color: orange;
}
.tree-checkbox_isIndeterminate::after {
  content: "•";
}
.tree-checkbox_isChecked {
  background-color: #2b78d7;
}
.tree-checkbox_isChecked::after {
  content: "✓";
}
.tree-list {
  padding: 0;
  margin: 0;
  list-style-type: none;
}
.tree-list-item {
  padding: 3px;
  color: #444;
  font-size: 12px;
  list-style-type: none;
}
.tree-list-item.level-0 {
  padding-left: 0;
}
.tree-list-item.level-1 {
  padding-left: 10px;
}
.tree-list-item.level-2 {
  padding-left: 20px;
}
.tree-list-item.level-3 {
  padding-left: 30px;
}
.tree-list-item.level-4 {
  padding-left: 40px;
}


