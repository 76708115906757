.actionBuilder {
  height: 100%;
}

.actionBuilder .tree-row .text {
  font-size: 14px;
  padding-left: 0;
}

.actionBuilder .tree-row-container {
  width: 100%;
  padding-top: 2px;
  padding-bottom: 2px;
}

.actionBuilder .tree-row-container:hover {
  background-color: rgba(0, 0, 0, 0.04);
}


